import React, { Component, Fragment } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import CKApi from '../../services/api-helper';
import Form from '../../components/form/Form';
import FormTextInput from '../../components/form/FormTextInput';
import FormCheckboxSingle from '../../components/form/FormCheckboxSingle';
import IPInstitutionMessage from './IPInstitutionMessage';
import InstitutionLoginLink from './InstitutionLoginLink';
import CSASOfflineMessage from './CSASOfflineMessage';
import InternalLink from '../../components/common/InternalLink';
import AuthPopout from '../../components/common/AuthPopout';
import queryStringUtils from '../../utils/queryStringUtils';
import { trackEvent } from '../../utils/eventTrackUtils';
import { Helmet } from 'react-helmet';
import { isEmpty } from 'ramda';
import { MainContext } from '../../context/main-context';
import DOMPurify from 'dompurify';

const messages = defineMessages({
  LoginArialabel: {
    id: 'LoginPage.arialabel.loginForm',
    defaultMessage: 'Login Form'
  },
  LoginPageTitle: {
    id: 'LoginPage.title.text',
    defaultMessage: 'Login - ClinicalKey Student'
  },
  LoginUsernameLabel: {
    id: 'LoginPage.label.username',
    defaultMessage: 'Email Address'
  },
  LoginPasswordLabel: {
    id: 'LoginPage.label.password',
    defaultMessage: 'Password'
  },
  LoginRememberLabel: {
    id: 'LoginPage.label.remember',
    defaultMessage: 'Remember me'
  },
  LoginInvalidCredentials: {
    id: 'LoginPage.error.invalidCredentials',
    defaultMessage: 'Invalid username or password.'
  },
  LoginNoUsername: {
    id: 'LoginPage.error.noUsername',
    defaultMessage: 'Username is required.'
  },
  LoginNoPassword: {
    id: 'LoginPage.error.noPassword',
    defaultMessage: 'Password is required.'
  },
  LoginButtonSubmit: {
    id: 'LoginPage.button.submit',
    defaultMessage: 'Log in'
  },
  LoginButtonCancel: {
    id: 'LoginPage.button.cancel',
    defaultMessage: 'Cancel'
  },
  LoginNoAccess: {
    id: 'LoginPage.error.noAccess',
    defaultMessage: 'We do not recognize that you have access to ClinicalKey Student.'
  },
  LoginTroubleshooting: {
    id: 'LoginPage.error.troubleshooting',
    defaultMessage: 'View troubleshooting guide '
  },
  LoginFailed: {
    id: 'LoginPage.error.loginFailure',
    defaultMessage: 'Login failed.'
  },
  LoginInstructions: {
    id: 'LoginPage.message.credentialsInstructions',
    defaultMessage: 'Log in using your'
  },
  LoginStudent: {
    id: 'LoginPage.message.credentialsStudent',
    defaultMessage: 'You have reached ClinicalKey Student for Medical. For Nursing click'
  },
  LoginNursing: {
    id: 'LoginPage.message.credentialsNursing',
    defaultMessage: 'You have reached ClinicalKey Student for Nursing. For Medical click'
  },
  LoginHere: {
    id: 'LoginPage.message.here',
    defaultMessage: 'here.'
  },
  showPassword: {
    id: 'RegisterPage.label.showPassword',
    defaultMessage: 'Show Password'
  },
  hidePassword: {
    id: 'RegisterPage.label.hidePassword',
    defaultMessage: 'Hide Password'
  }
});

const getOtherInstitutionLoginHref = (studyToolsLoginType) => {
  if (studyToolsLoginType) {
    return queryStringUtils.addStudyToolsParam('/institution-login', studyToolsLoginType);
  }

  const { target } = queryStringUtils.parse(window.location.search);

  const url = target ? `/institution-login?target=${target}` : '/institution-login';

  return url;
};

class Login extends Component {
  state = {
    apiError: {},
    loggingIn: false,
    isPopupOpen: false,
    popupType: ''
  };

  static contextType = MainContext;

  otherInstitutionLoginHref = getOtherInstitutionLoginHref(this.props.studyToolsLoginType);

  setDataAnalyticsLogin = () => {
    trackEvent('loginStart');
  };

  handleValidSubmit = (userInputs) => {
    localStorage.removeItem('ckst-performance-start-data');
    localStorage.removeItem('ckst-performance-end-data');
    this.setDataAnalyticsLogin();
    const params = {
      username: userInputs.username,
      password: userInputs.password,
      remember_me: userInputs.rememberMe
    };

    CKApi.post('/student/api/auth/login', params)
      .then((response) => { this.handleApiSuccess(response); })
      .catch(this.handleApiError);
  };

  formattedErrorMessage = () => {
    return (
      <>
        <span>{this.context.intl.formatMessage(messages.LoginNoAccess)}</span>
        <div>
          <span>{this.context.intl.formatMessage(messages.LoginTroubleshooting)}</span>
          <span className='c-ckm-link' role='button' tabIndex='0' onClick={this.showPopup('loginMethods')} onKeyDown={this.handleKeyDown}>
            <FormattedMessage
              id='LoginPage.message.loginMethodButton'
              defaultMessage=' here.'
            />
          </span>
        </div>
      </>
    );
  };

  handleApiError = ({ response }) => {
    let message;
    trackEvent('loginFailure');
    if (!isEmpty(this.state.apiError)) this.setState({ apiError: {} });

    switch (response.status) {
      case 401:
        message = { message: this.context.intl.formatMessage(messages.LoginInvalidCredentials), name: 'username' };
        break;
      case 403:
        message = { message: this.formattedErrorMessage };
        break;
      default:
        message = { message: this.context.intl.formatMessage(messages.LoginFailed) };
    }
    this.setState({ apiError: message });
  };

  handleApiSuccess = (response) => {
    this.setState({ apiError: {} });
    this.handleLoginComplete(response);
  };

  handleLoginComplete = (response) => {
    if (response.data.status === 'PATH_CHOICE') {
      this.context.showPathChoice(response.data.path_choices);
      this.setState({ loggingIn: true });
    } else {
      trackEvent('newPage', {
        loginSuccess: 'true'
      });
      this.doRedirect();
    }
  };

  handleKeyDown = (evt) => {
    if (evt?.keyCode === 13 || evt?.keyCode === 32) {
      this.showPopup(evt);
    }
  }

  showPopup = (type) => (evt) => {
    evt.preventDefault();
    this.setState({
      isPopupOpen: true,
      popupType: type
    });
  };

  handlePopoutClose = () => {
    this.setState({
      isPopupOpen: false,
      popupType: ''
    });
  };

  doRedirect = () => {
    if (this.props.studyToolsLoginType) {
      const onlineStudyTools = this.props.studyToolsLoginType === 'online';
      this.context.doRedirect(`/api/vst/redirect?online=${onlineStudyTools}`);
    } else {
      const parsedQueryString = queryStringUtils.parse(window.location.search);
      this.context.doRedirect(parsedQueryString.target || '/');
    }
  };

  componentWillMount () {
    const { user } = this.context;
    if (user && user.isPathChoice()) {
      this.context.showPathChoice(user.path_choices);
    }
  }

  componentDidMount () {
    this.setState({ title: this.context.intl.formatMessage(messages.LoginPageTitle) });
  }

  render () {
    const { intl, user, productConfig } = this.context;
    const csasOffline = user.isEmulatorAccount();
    const ipAuthFailed = user.status === 'FAILED';
    const showRegisterLink = (this.props.bulkId || !ipAuthFailed) && !user.isAnonGuest();
    const showNursingLink = this.props.studyToolsLoginType && productConfig.context === 'student';
    const showAccountBanner = !user.isAnonGuest() && !this.props.bulkId && !ipAuthFailed && !csasOffline && user.account_name;

    return (
      <div className='c-ckm-background-auth'>
        {showAccountBanner &&
          <IPInstitutionMessage accountName={user.account_name} />}
        <div className='u-ckm-inner-container'>
          <Helmet>
            <title>{this.state.title}</title>
          </Helmet>
          {this.state.isPopupOpen &&
            <AuthPopout
              handlePopoutClose={this.handlePopoutClose}
              type={this.state.popupType}
              otherInstitutionLoginHref={this.otherInstitutionLoginHref}
              intl={intl}
            />}
          {csasOffline
            ? <CSASOfflineMessage />
            : <div className='c-ckm-auth'>
              <div className='c-ckm-auth__tip c-ckm-auth__tip--middle u-els-padding o-els-flex-layout'>
                <span className='u-els-color-secondary o-els-flex-layout__item c-ckm-auth__tip-icon-container' aria-hidden>
                  <svg className='c-ckm-auth__tip-icon o-els-icon-svg o-els-icon-svg--2x'>
                    <use xlinkHref='#icon-sprite_els-hmds-icon-lightbulb' />
                  </svg>
                </span>
                <span className='c-ckm-auth__tip-text o-els-flex-layout__item o-els-flex-layout__item--grow'>
                  {productConfig.context === 'student'
                    ? <>
                      {intl.formatMessage(messages.LoginStudent)}&nbsp;
                      <a className='c-ckm-link' href='/student/nursing/login'>
                        {intl.formatMessage(messages.LoginHere)}
                      </a>
                    </>
                    : <>
                      {intl.formatMessage(messages.LoginNursing)}&nbsp;
                      <a className='c-ckm-link' href='/student/login'>
                        {intl.formatMessage(messages.LoginHere)}
                      </a>
                    </>}
                </span>
              </div>
              <div className='c-ckm-form__divider c-els-divider c-els-divider--1o2' />
              <div className='c-ckm-auth__panel'>
                <article className='c-ckm-auth__panel-col c-ckm-auth__panel-col--border'>
                  <div className='c-ckm-auth__heading o-els-flex-layout o-els-flex-layout--space-between'>
                    <h1 className='o-els-flex-layout__item u-els-font-size-h2'>
                      <FormattedMessage
                        id='LoginPage.head.yourDetails'
                        defaultMessage='Log In'
                      />
                    </h1>
                  </div>
                  {showRegisterLink &&
                    <p>
                      <FormattedMessage
                        id='LoginPage.message.noRegistration'
                        defaultMessage='New user?'
                      />
                      {' '}
                      <InternalLink href='/registration'>
                        <FormattedMessage
                          id='LoginPage.link.registerHere'
                          defaultMessage='Register here'
                        />
                      </InternalLink>
                      <br />
                    </p>}
                  <div className='o-ckm-content-panel' role='region' aria-label={intl.formatMessage(messages.LoginArialabel)}>
                    {showNursingLink && <div>
                      <a
                        className='c-ckm-auth__button c-els-button c-els-button--secondary c-els-button--small c-els-button--icon c-els-button--icon-right'
                        href={`/student/nursing/login?studyToolsLogin=${DOMPurify.sanitize(this.props.studyToolsLoginType)}`}
                      >
                        <FormattedMessage
                          id='LoginPage.link.nursingRedirect'
                          defaultMessage='Nursing users, log in here'
                        />
                        <svg aria-hidden='true'>
                          <use xlinkHref='#icon-sprite_els-hmds-icon-chevron-right' />
                        </svg>
                      </a>
                    </div>}
                    {this.state.loggingIn ? (
                      <h3>
                        <FormattedMessage
                          id='LoginPage.label.loggingIn'
                          defaultMessage='Logging in...'
                        />
                      </h3>
                    ) : (
                      <Form
                        intl={intl}
                        validSubmit={this.handleValidSubmit}
                        apiError={this.state.apiError}
                        apiSuccess={this.state.apiSuccess}
                        buttonSubmitText={intl.formatMessage(messages.LoginButtonSubmit)}
                      // buttonCancelText={intl.formatMessage(messages.LoginButtonCancel)}
                      >
                        <p className='o-els-flex-layout__item'>
                          <FormattedMessage
                            id='LoginPage.text.required'
                            defaultMessage='* indicates a required field'
                          />
                        </p>
                        <FormTextInput
                          label={intl.formatMessage(messages.LoginUsernameLabel)}
                          type='text'
                          name='username'
                          autocomplete='off'
                          isRequired
                          requiredErrorMessage={intl.formatMessage(messages.LoginNoUsername)}
                          mouseflowIgnore
                        />
                        <FormTextInput
                          label={intl.formatMessage(messages.LoginPasswordLabel)}
                          type='password'
                          name='password'
                          autocomplete='off'
                          isRequired
                          requiredErrorMessage={intl.formatMessage(messages.LoginNoPassword)}
                          mouseflowIgnore
                          showPasswordToggle
                          showPasswordLabel={intl.formatMessage(messages.showPassword)}
                          hidePasswordLabel={intl.formatMessage(messages.hidePassword)}
                        />
                        <p className='c-ckm-form-sub-text'>
                          <InternalLink href='/forgot-password'>
                            <FormattedMessage
                              id='LoginPage.link.forgotPassword'
                              defaultMessage='Forgot password?'
                            />
                          </InternalLink>
                        </p>
                        <FormCheckboxSingle
                          name='rememberMe'
                          label={intl.formatMessage(messages.LoginRememberLabel)}
                          initialCheck
                          instructionsID='u-ckm-login-warningSharedData'
                        />
                        <p className='u-ckm-sub u-ckm-margin--reset' id='u-ckm-login-warningSharedData'>
                          <FormattedMessage
                            id='LoginPage.warning.sharedDevice'
                            defaultMessage='Not recommended on shared devices.'
                          />
                        </p>
                      </Form>
                    )}
                  </div>
                </article>
                {!this.props.bulkId && <aside className='c-ckm-auth__panel-col'>
                  <InstitutionLoginLink
                    otherInstitutionLoginHref={this.otherInstitutionLoginHref}
                    setDataAnalyticsLogin={this.setDataAnalyticsLogin}
                  />
                </aside>}
              </div>
            </div>}
        </div>
      </div>
    );
  }
}

export default Login;

Login.displayName = 'Login';
